import React, { useEffect } from 'react';
import { useMe } from './useAuth';

const useIsStatusU = () => {
  const [isStatusU, setIsStatusU] = React.useState(false);
  const { data } = useMe();

  useEffect(() => {
    if (
      data?.principals[0]?.accounts.some(
        (account: any) => account.status === 'U',
      )
    ) {
      setIsStatusU(true);
    }
  }, [data]);

  return isStatusU;
};

export default useIsStatusU;
