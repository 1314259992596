import { Switch, Route, RouteComponentProps, Redirect } from 'react-router-dom';
import { MarketOverview } from 'pages/MarketOverview/MarketOverview';
import { PrivateRoute } from 'components/RouteComponents';
import Instrument from 'pages/Instrument';
import Transactions, {
  TransactionDetails,
  OrderDetails,
} from 'pages/Transactions';
import MyOverview from 'pages/MyOverview';
import Quotelist from 'pages/Quotelist';
import MarginApply from 'pages/MarginApply';
import StockChart from 'pages/StockChart';
import HTestPageLegacy from 'pages/HTestPageLegacy';

import News from 'pages/News';
import Alerts from 'pages/Alerts';
import Analysis from 'pages/Analysis';
import { Box } from '@oms/ui-box';

export const PrivateRoutes = () => {
  // TODO: /landing should probably be changed BE if we have no use for it
  // TODO: Lazy-loading, BUT please use prefetching or transitions, or anything to avoid the white blinking please :D
  return (
    <Switch>
      <Redirect from="/landing" to="/market" />
      <PrivateRoute exact path="/" component={MyOverview} />
      <PrivateRoute exact path="/market" component={MarketOverview} />
      <PrivateRoute exact path="/quotelist" component={Quotelist} />
      <PrivateRoute
        exact
        path="/instrument/:itemSector"
        component={Instrument}
      />
      <PrivateRoute exact path="/alerts" component={Alerts} />
      <PrivateRoute exact path="/analysis" component={Analysis} />
      <PrivateRoute exact path="/transactions" component={Transactions} />
      <PrivateRoute exact path="/order/:orderId" component={OrderDetails} />
      <PrivateRoute
        exact
        path="/order/:orderId/transaction/:transactionId"
        component={TransactionDetails}
      />

      <PrivateRoute exact path="/news" component={News} />
      <PrivateRoute path="/news/article/:referenceId" component={News} />
      <PrivateRoute path="/news/company/:item" component={News} />

      <PrivateRoute exact path="/margin" component={MarginApply} />
      <PrivateRoute
        exact
        path="/detailed-chart/:itemSector"
        component={StockChart}
      />
      <PrivateRoute exact path="/detailed-chart" component={StockChart} />
      <PrivateRoute exact path="/htestLegacy" component={HTestPageLegacy} />
      <Route
        path="*"
        component={({ location }: RouteComponentProps) => (
          <Box flexDirection="column" center height="60vh">
            <img src="/logo.svg" alt="Sparebank1 Logo" />
            <h1>Fant ikke {location.pathname}</h1>
          </Box>
        )}
      />
    </Switch>
  );
};
