import styled from '@emotion/styled';
import { Field, Fieldset } from '@oms/ui-field';
import { Radio, RadioGroup } from '@oms/ui-radio';
import { Checkbox, CheckboxGroup } from '@oms/ui-checkbox';
import { TextInput } from '@oms/ui-text-input';
import { Textarea } from '@oms/ui-textarea';
import OriginOfFunds from '../OriginOfFunds';
import IsFundsFromAbroad from '../IsFundsFromAbroad';
import { Heading } from '@oms/ui-heading';
import MonthlyTransactions from '../MonthlyTransactions';
import TransactionTradeSize from '../TransactionTradeSize';
import TransactionFundsSize from '../TransactionFundsSize';
import MoveExistingSecurities from '../MoveExistingSecurities';
import { required, notEmptyArray, composeValidators } from '../validate';
import Condition from 'components/FormComponents/Condition';
import { Stack } from '@oms/ui-stack';
const Divider = styled.hr`
  margin: 1rem 0;
  color: lightgray;
`;
export const Laundering = () => {
  return (
    <Stack gap={4}>
      <OriginOfFunds />
      <IsFundsFromAbroad />
      <Divider />
      <Heading>
        Skal kundeforholdet brukes til å gjennomføre handel på vegne av andre?
      </Heading>
      <Fieldset
        as={RadioGroup}
        name="kycBusinessRelationship.tradeOnBehalfOthers"
        legend="Velg det som passer best"
      >
        <Field as={Radio} label="Ja" value="true" validate={required} />
        <Field as={Radio} label="Nei" value="false" validate={required} />
      </Fieldset>
      <Condition when="kycBusinessRelationship.tradeOnBehalfOthers" is="true">
        <Field
          name="kycBusinessRelationship.tradeOnBehalfOthersDescription"
          as={TextInput}
          label="Beskrivelse"
          validate={required}
        />
      </Condition>
      <Divider />
      <Heading>
        Skal andre benytte ditt kundeforhold i SB1M og handle på dine vegne?
      </Heading>
      <Fieldset
        as={RadioGroup}
        name="kycBusinessRelationship.othersActOnYourBehalf"
        legend="Velg det som passer best"
      >
        <Field as={Radio} label="Ja" value="true" validate={required} />
        <Field as={Radio} label="Nei" value="false" validate={required} />
      </Fieldset>
      <Condition when="kycBusinessRelationship.othersActOnYourBehalf" is="true">
        <Field
          name="kycBusinessRelationship.othersActOnYourBehalfDescription"
          as={TextInput}
          label="Beskrivelse"
          validate={required}
        />
      </Condition>
      <Divider />
      <Heading>Skal det oppbevares midler på vegne av andre?</Heading>
      <Fieldset
        as={RadioGroup}
        name="kycFunds.heldOnBehalfOthers"
        legend="Velg det som passer best"
      >
        <Field as={Radio} label="Ja" value="true" validate={required} />
        <Field as={Radio} label="Nei" value="false" validate={required} />
      </Fieldset>
      <Condition when="kycFunds.heldOnBehalfOthers" is="true">
        <Field
          name="kycFunds.heldOnBehalfOthersDescription"
          as={TextInput}
          label="Beskrivelse"
          validate={required}
        />
      </Condition>
      <Heading>Hva er formålet med ditt kundeforhold hos oss?</Heading>
      <Fieldset
        as={CheckboxGroup}
        name="additionalInformation.laundering.purpose"
        legend="Gjelder bakgrunnen for inngåelse av kundeforholdet."
      >
        <Field
          as={Checkbox}
          label="Trading spekulasjon"
          value="trading"
          validate={composeValidators(required, notEmptyArray)}
        />
        <Field
          as={Checkbox}
          validate={composeValidators(required, notEmptyArray)}
          label="Kortsiktig sparing"
          value="shortTermSavings"
        />
        <Field
          as={Checkbox}
          validate={composeValidators(required, notEmptyArray)}
          label="Langsiktig sparing"
          value="longTermSavings"
        />
        <Field
          as={Checkbox}
          label="Pensjon"
          value="pension"
          validate={composeValidators(required, notEmptyArray)}
        />
        <Field
          as={Checkbox}
          label="Handel for nærstående, for eksempel barn"
          value="closeRelatives"
          validate={composeValidators(required, notEmptyArray)}
        />
        <Field
          as={Checkbox}
          label="Risikostyring"
          value="riskManagement"
          validate={composeValidators(required, notEmptyArray)}
        />
        <Field
          as={Checkbox}
          label="Annet"
          value="other"
          validate={composeValidators(required, notEmptyArray)}
        />
        {/* <ValidationError name="additionalInformation.laundering.purpose" /> */}
      </Fieldset>
      <Condition
        when="additionalInformation.laundering.purpose"
        includes="other"
      >
        <Field
          as={TextInput}
          label="Beskrivelse annet"
          validate={composeValidators(required, notEmptyArray)}
          name="additionalInformation.laundering.otherPurposeDescription"
        />
      </Condition>
      <Field
        as={Textarea}
        label="Øvrige kommentarer"
        name="additionalInformation.laundering.comments"
      />
      <MonthlyTransactions />
      <TransactionTradeSize />
      <TransactionFundsSize />
      <Divider />
      <MoveExistingSecurities />
    </Stack>
  );
};
export default Laundering;
