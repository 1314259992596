import styled from '@emotion/styled';
import { Field, Fieldset } from '@oms/ui-field';
import { Radio, RadioGroup } from '@oms/ui-radio';
import DescriptionField from './DescriptionField';
import EmploymentStatus from '../EmploymentStatus';
import YearlyIncomeBeforeTax from '../YearlyIncomeBeforeTax';

import { required } from '../validate';
import { Stack } from '@oms/ui-stack';
import Condition from 'components/FormComponents/Condition';

const Divider = styled.hr`
  margin: 1rem 0;
  color: lightgray;
`;

export const Profession = () => {
  return (
    <Stack gap={4}>
      <EmploymentStatus />
      <Divider />
      <YearlyIncomeBeforeTax />
      <Divider />
      <Fieldset
        as={RadioGroup}
        validate={required}
        name="profession.relevantExperience"
        legend="Arbeider/har du arbeidet innen finansiell sektor i minst ett år i en
        stilling som forutsetter kjennskap til de påtenkte finansielle
        plasseringene?"
      >
        <Field as={Radio} label="Ja" value="true" />
        <Field as={Radio} label="Nei" value="false" />
      </Fieldset>
      <Condition when="profession.relevantExperience" is="true">
        <DescriptionField name="profession.relevantExperienceDescription" />
      </Condition>
    </Stack>
  );
};

export default Profession;
