import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-final-form';
import styled from '@emotion/styled';
import { Box } from '@oms/ui-box';
import { Text } from '@oms/ui-text';
import arrayMutators from 'final-form-arrays';

import PageHeader from '../PageHeader';
import { Button } from '@oms/ui-button';
import { pepDecorator } from '../PoliticallyExposed/decorator';
import { termsDecorator } from '../TermsAndDisclaimer/decorator';
import { FormValues } from '../types';
import { knowledgeDecorator } from '../Knowledge/decorator';
import { launderingDecorator } from '../Laundering/decorator';
import Warning from '../Knowledge/Warning';
import { Notification } from '@oms/ui-notification';

const generalValidation = (values: any) => {
  let errors = {};

  const { warningAcknowledged, showWarning } = values?.knowledge || {};

  if (showWarning && !warningAcknowledged) {
    errors = {
      ...errors,
      expediency: 'Må godta advarsel',
    };
  }

  return errors;
};

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 5px;
`;

const StyledForm = styled.form`
  margin-top: 1rem;
  max-width: 40rem;
  margin: 0 auto;
  padding: 1rem;
  min-height: 1000px;
`;

type Props = {
  step: number;
  initialValues: Partial<FormValues>;
  children: React.ReactNode;
  onSubmit: (values: FormValues) => Promise<any> | void;
  isPDFRender: boolean;
};

export const Wizard = ({
  step,
  initialValues,
  children,
  onSubmit,
  isPDFRender,
}: Props) => {
  const history = useHistory();
  const [, setValues] = useState(initialValues);

  const activePage = React.Children.toArray(children)[step];
  const totalPages = React.Children.count(children);
  const isLastPage = step === totalPages - 1;

  const next = (newValues: Partial<FormValues>) => {
    const newPage = Math.min(step + 1, totalPages - 1);

    setValues(newValues);

    history.replace(`/expediency-test/page/${newPage}`, { values: newValues });
  };

  const previous = (newValues: Partial<FormValues>) => {
    const newPage = Math.max(step - 1, 0);

    setValues(newValues);

    history.replace(`/expediency-test/page/${newPage}`, { values: newValues });
  };

  const handleSubmit = (values: Partial<FormValues>) => {
    if (isLastPage) {
      return onSubmit(values as FormValues);
    } else {
      next(values);
    }
  };

  return (
    <>
      <PageHeader step={step} />
      {/* {step === 0 && (
        <br>
        <div style={}>

        </div>
              <Notification
                title="GJELDER EKSISTERENDE KUNDER UTEN BEHOLDNING:"
                status="error"
              >
                Dersom KOS skjema ikke fylles ut snarest vil du risikere at ditt
                kundeforhold avvikles.
              </Notification>
              </br>
            )} */}
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        mutators={{ ...arrayMutators }}
        decorators={[
          pepDecorator,
          termsDecorator,
          knowledgeDecorator,
          launderingDecorator,
        ]}
        validate={generalValidation}
      >
        {({
          form,
          handleSubmit,
          submitting,
          values,
          hasValidationErrors,
          errors,
          submitError,
        }) => (
          <StyledForm onSubmit={handleSubmit}>
            {step === 0 && (
              <Notification
                title="GJELDER EKSISTERENDE KUNDER UTEN BEHOLDNING:"
                status="error"
                style={{ marginBottom: '1rem' }}
              >
                Dersom KOS skjema ikke fylles ut snarest vil du risikere at ditt
                kundeforhold avvikles.
              </Notification>
            )}
            <Box>{activePage}</Box>
            {step === 2 && values?.knowledge?.showWarning && (
              <Box mt={8}>
                <Warning
                  acknowledged={values?.knowledge?.warningAcknowledged || false}
                  onAcknowledge={(value: boolean) => {
                    //@ts-ignore
                    form.change('knowledge.warningAcknowledged', value);
                  }}
                  expediency={values.knowledge?.expediencyResults}
                />
              </Box>
            )}
            {submitError && (
              <Notification title="Feil ved innsending" status="error">
                {submitError?.message}
                <Text>
                  Incident ID:{' '}
                  {submitError?.response?.headers?.get?.('x-oms-incidentid')}
                </Text>
              </Notification>
            )}
            {!isPDFRender && !errors?.expediency && (
              <Buttons>
                {step > 0 && (
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={() => previous(values)}
                  >
                    Tilbake
                  </Button>
                )}
                {!isLastPage && (
                  <Button
                    ml="auto"
                    type="submit"
                    title="Neste"
                    disabled={hasValidationErrors}
                  >
                    Neste
                  </Button>
                )}
                {isLastPage && (
                  <Button
                    type="submit"
                    title="Bestill aksjetjenesten"
                    disabled={submitting || hasValidationErrors}
                    isPending={submitting}
                  >
                    Signer med BankID
                  </Button>
                )}
              </Buttons>
            )}
          </StyledForm>
        )}
      </Form>
    </>
  );
};

export const WizardPage = ({ children }: any) => {
  return children;
};

export default Wizard;
