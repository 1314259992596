import createDecorator from 'final-form-calculate';
import { calculateExpediency } from 'utils/expediencyTest';
import { EquityCertificates, Knowledge, Stocks } from '../types';

export const knowledgeDecorator = createDecorator(
  {
    field: 'knowledge.stocks',
    updates: {
      'knowledge.expediencyResults.stocks': (
        { knowledgeLevel, yearsExperience }: Stocks = {} as any,
      ) => calculateExpediency(knowledgeLevel, Number(yearsExperience)),
    },
  },
  {
    field: 'knowledge.equityCertificates',
    updates: {
      'knowledge.expediencyResults.equityCertificates': (
        { knowledgeLevel, yearsExperience }: EquityCertificates = {} as any,
      ) => calculateExpediency(knowledgeLevel, Number(yearsExperience)),
    },
  },
  {
    field: 'knowledge.expediencyResults',
    updates: {
      'knowledge.showWarning': ({
        stocks,
        equityCertificates,
      }: Knowledge['expediencyResults'] = {}) => {
        if (stocks === 'NO' || equityCertificates === 'NO') return true;
        return false;
      },
    },
  },
);
