import { NumberInput } from 'components/NumberInput'; //'@oms/ui-number-input';
import { Field, Fieldset } from '@oms/ui-field';
import { Radio, RadioGroup } from '@oms/ui-radio';
import {
  required,
  posNumber,
  lessThanMaxYear,
  composeValidators,
  noDecimals,
} from '../validate';
import { Stack } from '@oms/ui-stack';
import { Heading } from '@oms/ui-heading';

export const KnowledgeLevelStocks = () => {
  //Børsnoterte aksjer
  return (
    <Stack gap={1}>
      <Heading>Aksjer</Heading>
      <Fieldset
        as={RadioGroup}
        name="knowledge.stocks.knowledgeLevel"
        legend="Kunnskapsnivå"
      >
        <Field as={Radio} validate={required} label="Høy" value="HIGH" />
        <Field as={Radio} validate={required} label=" Middels" value="MEDIUM" />
        <Field as={Radio} validate={required} label=" Lav" value="LOW" />
      </Fieldset>

      <Field
        as={NumberInput}
        lang="nb"
        validate={composeValidators(noDecimals, required, posNumber)}
        name="knowledge.stocks.numberOfTrades"
        label="Antall handler siste år"
        inputMode="numeric"
      />

      <Field
        as={NumberInput}
        lang="nb"
        name="knowledge.stocks.yearsExperience"
        label="Antall år erfaring"
        inputMode="numeric"
        validate={composeValidators(
          noDecimals,
          required,
          lessThanMaxYear,
          posNumber,
        )}
      />
    </Stack>
  );
};

export default KnowledgeLevelStocks;
