import { Field } from '@oms/ui-field';
import { TextInput } from '@oms/ui-text-input';
import { required } from '../validate';

type Props = { name: string };

const DescriptionField = ({ name }: Props) => {
  return (
    <Field
      name={`${name}`}
      as={TextInput}
      label="Beskrivelse"
      validate={required}
    />
  );
};

export default DescriptionField;
