import { Field, Fieldset } from '@oms/ui-field';
import Condition from 'components/FormComponents/Condition';
import { Heading } from '@oms/ui-heading';
import { Stack } from '@oms/ui-stack';
import { TextInput } from '@oms/ui-text-input';
import { Checkbox, CheckboxGroup } from '@oms/ui-checkbox';
import { required, notEmptyArray, composeValidators } from '../validate';
import { InfoBubble } from '@oms/ui-info-bubble';
import { Box } from '@oms/ui-box';

export const OriginOfFunds = () => {
  return (
    <Stack gap={4}>
      <Heading>Hvor kommer beløpet som du skal investere fra? </Heading>
      <Fieldset
        as={CheckboxGroup}
        name="kycOriginOfFunds"
        legend="Du kan velge flere alternativer"
      >
        <Field
          as={Checkbox}
          label="Lønn, studielån, næringsvirksomhet, pensjon, bidrag eller dagpenger"
          value="GENERAL_INCOME"
          validate={composeValidators(required, notEmptyArray)}
        />
        <Field
          as={Checkbox}
          label="Sparing eller gevinst fra verdipapirhandel"
          value="SAVINGS"
          validate={composeValidators(required, notEmptyArray)}
        />
        <Field
          as={Checkbox}
          label="Arv eller gave"
          value="INHERITANCE_OR_GIFT"
          validate={composeValidators(required, notEmptyArray)}
        />
        <Field
          as={Checkbox}
          label="Salg av eiendeler"
          value="SALE_OF_ASSETS"
          validate={composeValidators(required, notEmptyArray)}
        />
        <Field
          as={Checkbox}
          label="Forsikringsutbetaling"
          value="INSURANCE_PAYOUT"
          validate={composeValidators(required, notEmptyArray)}
        />
        <Field
          as={Checkbox}
          label="Annet"
          value="OTHER"
          validate={composeValidators(required, notEmptyArray)}
        />
      </Fieldset>
      <Condition when="kycOriginOfFunds" includes="SALE_OF_ASSETS">
        <Box display="flex" width="90%">
          <Field
            name="kycAssetsSold"
            as={TextInput}
            label="Hvilke eiender har du solgt?"
            validate={required}
            width="100%"
          />
          <Box display="flex" alignItems="flex-end" mb={2}>
            <InfoBubble aria-label="beskrivelse" ml={4}>
              Salg av eiendeler kan for eksempel være salg av bolig, eiendom,
              foretak, bil eller båt.
            </InfoBubble>
          </Box>
        </Box>
      </Condition>
      <Condition when="kycOriginOfFunds" includes="OTHER">
        <Field
          name="kycExplainOriginOfFunds"
          as={TextInput}
          label="Skriv hvor beløpet kommer fra"
          validate={required}
        />
      </Condition>
    </Stack>
  );
};

export default OriginOfFunds;
