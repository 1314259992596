import { lazy } from 'react';
import moment from 'moment';
//@ts-ignore
import locale from 'moment/locale/nb';
import { I18nProvider } from '@lingui/react';
import noCatalog from '@oms/components-i18n/locale/no/messages';
import { Redirect, Route, Switch } from 'react-router-dom';
//@ts-ignore
import { handleTargetBlankLinks } from 'external/mobilbank-communication';
// import NotFoundPage from 'pages/NotFoundPage';
import { ReactQueryDevtools } from 'react-query/devtools';
import { PrivateRoutes } from 'private-routes';
import { Providers } from 'context';
import { LoginPage } from 'pages/Login';
import { Gdpr } from 'pages/Gdpr';
import ExpediencyTestPage from 'pages/ExpediencyTestPage';
import { SignicatCancelledPage } from 'pages/ExpediencyTestPage/SignicatCancelledPage';
import { SignicatSuccessPage } from 'pages/ExpediencyTestPage/SignicatSuccessPage';

const AdminLoginPage = lazy(() => import('pages/admin/LoginPage'));
const AdminRoutes = lazy(() => import('admin-routes'));
const AdminChangePasswordPage = lazy(() =>
  import('pages/admin/ChangePasswordPage'),
);
// const LoginPage = lazy(() => import('pages/LoginPage'));
// const LandingPage = lazy(() => import('pages/LandingPage'));

// SB1 script for listening to click on links with target="_blank" and
// override the event with a mobile wrapper app navigation
handleTargetBlankLinks();

// Load norwegian locale
moment.updateLocale('nb', locale);
moment.locale('nb');

const catalogs = {
  no: noCatalog,
};

if (process.env.NODE_ENV === 'development') {
  require('debug').enable('dev');
}

const Routes = () => (
  <Switch>
    {/* Public routes */}
    <Route path="/login" component={LoginPage} />
    <Route path="/admin/login" component={AdminLoginPage} />
    <Route path="/admin/change-password" component={AdminChangePasswordPage} />
    {/* <Route path="/gatekeeper" component={GatekeeperPage} /> */}
    <Route path="/gdpr" component={Gdpr} />
    <Redirect exact from="/expediency-test" to="/expediency-test/page/0" />
    <Route path="/expediency-test/page/:step" component={ExpediencyTestPage} />
    <Route path="/signing-success" component={SignicatSuccessPage} />
    <Route path="/signing-cancelled" component={SignicatCancelledPage} />
    {/* <Route path="/h-test" component={HtestPage} /> */}

    {/* Private/Admin routes */}
    <Route path="/admin" component={AdminRoutes} />
    <Route path="/" component={PrivateRoutes} />

    <Route path="*" component={() => <h1>Not found!</h1>} />
  </Switch>
);

const Main = () => (
  <I18nProvider language="no" catalogs={catalogs}>
    <Providers>
      <ReactQueryDevtools initialIsOpen={false} />
      <Routes />
    </Providers>
  </I18nProvider>
);

export default Main;
