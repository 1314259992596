import { Field, Fieldset } from '@oms/ui-field';
import { Radio, RadioGroup } from '@oms/ui-radio';
import { Heading } from '@oms/ui-heading';
import { Stack } from '@oms/ui-stack';
import { required } from '../validate';

export const TransactionFundsSize = () => {
  return (
    <Stack gap={4}>
      <Heading>Hvor mye midler forventes investert?</Heading>
      <Fieldset
        as={RadioGroup}
        name="kycTransactionFundsSize"
        legend="Velg det som passer best"
      >
        <Field
          as={Radio}
          label="<1 mill."
          value="UNDER_1M"
          validate={required}
        />
        <Field
          as={Radio}
          label="1-5 mill."
          value="BETWEEN_1M_AND_5M"
          validate={required}
        />
        <Field
          as={Radio}
          label="5-10 mill."
          value="BETWEEN_5M_AND_10M"
          validate={required}
        />
        <Field
          as={Radio}
          label=">10 mill."
          value="10M_OR_ABOVE"
          validate={required}
        />
      </Fieldset>
    </Stack>
  );
};

export default TransactionFundsSize;
