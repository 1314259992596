import { Field, Fieldset } from '@oms/ui-field';
import { Radio, RadioGroup } from '@oms/ui-radio';
import Condition from 'components/FormComponents/Condition';
import { Heading } from '@oms/ui-heading';
import { Stack } from '@oms/ui-stack';
import { required } from '../validate';
import { TextInput } from '@oms/ui-text-input';

export const EmploymentStatus = () => {
  return (
    <Stack gap={4}>
      <Heading>Hva er ditt arbeidsforhold?</Heading>
      <Fieldset
        as={RadioGroup}
        name="kycEmploymentStatus"
        legend="Velg det som passer best"
      >
        <Field
          as={Radio}
          label="Fast eller midlertidig ansatt"
          value="PERMANENT_OR_TEMP_EMPLOYEE"
          validate={required}
        />
        <Field
          as={Radio}
          label="Eier eget foretak"
          value="OWN_MY_OWN_COMPANY"
          validate={required}
        />
        <Field as={Radio} label="Student" value="STUDENT" validate={required} />
        <Field
          as={Radio}
          label="Pensjonist/trygdemottaker"
          value="PENSIONER_OR_SOCIAL_SECURITY_RECIPIENT"
          validate={required}
        />
        <Field
          as={Radio}
          label="Arbeidsøkende"
          value="JOB_SEEKER"
          validate={required}
        />
        <Field
          as={Radio}
          label="Økonmisk uavhengig"
          value="FINANCIALLY_INDEPENDENT"
          validate={required}
        />
        <Field as={Radio} label="Annet" value="OTHER" validate={required} />
      </Fieldset>
      <Condition when="kycEmploymentStatus" is="OTHER">
        <Field
          name="kycExplainEmploymentStatus"
          as={TextInput}
          label="Forklar ditt arbeidsforhold"
          validate={required}
        />
      </Condition>
    </Stack>
  );
};

export default EmploymentStatus;
