import { useMemo, useState } from 'react';

//@ts-ignore
import Sentry from 'utils/sentry';
import useUser from 'utils/hooks/useUser';

import ExpediencyTest from 'components/ExpediencyTest';
import { FormValues, Payload } from 'components/ExpediencyTest/types';
import {
  useCheckExpediencyTest,
  useDelExpediencyTest,
  useGetExpediencyTest,
  usePostExpediencyTest,
} from 'utils/hooks/useExpediencyTest';
import { postSigning } from 'utils/hooks/useExpediencyTest/helpers';
import {
  checkIsPDFRender,
  getFormValues,
  getPayload,
} from 'utils/expediencyTest';
import { FORM_ERROR } from 'final-form';
import { Redirect } from 'react-router-dom';

/** Previously called HTestPage */
export const ExpediencyTestPage = () => {
  const [isPDFRender] = useState(checkIsPDFRender);

  const {
    data: { userId = '' },
  } = useUser();
  const { data: expediencyTestFlag, isLoading } = useCheckExpediencyTest(
    userId as string,
  );

  const { data: expediencyTest } = useGetExpediencyTest();
  const { mutateAsync: deleteExpediencyTest } = useDelExpediencyTest();
  const { mutateAsync: postExpediencyTest } = usePostExpediencyTest();

  const submit = (values: FormValues) => {
    const expediencyTestPayload: Payload = getPayload(values);

    return submitResults(expediencyTestPayload);
  };

  const submitResults = async (expediencyTestPayload: Payload) => {
    Sentry.withScope(scope => {
      scope.setLevel('info');
      scope.setTag('type', 'expediency-test');
      scope.setExtras({
        expediencyTestPayload,
      });
      Sentry.captureMessage(
        `About to submit expediency test for user ${userId}`,
      );
    });

    try {
      if (expediencyTest) {
        await deleteExpediencyTest(userId);
      }
      await postExpediencyTest({ payload: expediencyTestPayload, userId });
      await postSigning(userId).then((result: any) => {
        window.location.assign(result?.redirectUri);
      });
    } catch (e) {
      return { [FORM_ERROR]: e };
    }
  };

  const initialData = useMemo(() => {
    if (isPDFRender) return getFormValues(expediencyTest as Payload);
    return {};
  }, [expediencyTest, isPDFRender]);

  if (!expediencyTestFlag && !isLoading && !isPDFRender) {
    return <Redirect to="/" />;
  }

  return (
    <main>
      <ExpediencyTest
        initialData={initialData}
        onSubmit={submit}
        isPDFRender={isPDFRender}
      />
    </main>
  );
};

export default ExpediencyTestPage;
