import { Field, Fieldset } from '@oms/ui-field';
import { Radio, RadioGroup } from '@oms/ui-radio';
import Condition from 'components/FormComponents/Condition';
import { Heading } from '@oms/ui-heading';
import { Stack } from '@oms/ui-stack';
import { required } from '../validate';
import { TextInput } from '@oms/ui-text-input';
import { Select } from '@oms/ui-select';
import { countryOptions } from 'utils/locales';

export const IsFundsFromAbroad = () => {
  return (
    <Stack gap={4}>
      <Heading>Kommer noen av beløpet du skal investere fra utlandet?</Heading>
      <Fieldset
        as={RadioGroup}
        name="kycIsFundsFromAbroad"
        legend="Velg det som passer best"
      >
        <Field as={Radio} label="Ja" value="true" validate={required} />
        <Field as={Radio} label="Nei" value="false" validate={required} />
      </Fieldset>
      <Condition when="kycIsFundsFromAbroad" is="true">
        <Field
          name="kycCountryOfOrigin"
          as={Select}
          label="Hvilket land kommer beløpet i fra?"
          validate={required}
          items={countryOptions}
        />
        <Field
          name="kycOriginOfFundsFromAbroad"
          as={TextInput}
          label="Hva er opprinnelsen til midlene?"
          validate={required}
        />
      </Condition>
    </Stack>
  );
};

export default IsFundsFromAbroad;
