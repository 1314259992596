import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import useUser from '../useUser';
import {
  delExpediencyTest,
  getExpediencyFlag,
  getExpediencyTest,
  getPersonalInfo,
  getSigningStatus,
  postExpediencyTest,
  postSigning,
} from './helpers';

export const useGetExpediencyTest = (queryOptions?: UseQueryOptions) => {
  const {
    data: { userId = '' },
  } = useUser();

  return useQuery(
    ['expediency-test', userId],
    () => getExpediencyTest(userId),
    queryOptions,
  );
};

export const usePostExpediencyTest = () => {
  const queryClient = useQueryClient();
  return useMutation(postExpediencyTest, {
    onSuccess: () => {
      queryClient.invalidateQueries('expediency-test');
    },
  });
};

export const useCheckExpediencyTest = (
  userId: string,
  queryOptions?: UseQueryOptions,
) => {
  return useQuery(
    ['expediency-flag', userId],
    () => getExpediencyFlag(userId),
    { cacheTime: 0, staleTime: 1000, ...queryOptions },
  );
};

export const useDelExpediencyTest = () => {
  const queryClient = useQueryClient();
  return useMutation(delExpediencyTest, {
    onSuccess: () => {
      queryClient.invalidateQueries('expediency-test');
    },
  });
};

export const usePostSigning = () => {
  const queryClient = useQueryClient();
  return useMutation(postSigning, {
    onSuccess: () => {
      queryClient.invalidateQueries('signing-status');
    },
  });
};

export const useSigningStatus = () => {
  const {
    data: { userId = '' },
  } = useUser();

  return useQuery('signing-status', () => getSigningStatus(userId));
};

export const useGetPersonalInfo = () => {
  const {
    data: { userId = '' },
  } = useUser();

  return useQuery(
    ['expediency-test', 'personal-info', userId],
    () => getPersonalInfo(userId),
    { suspense: true },
  );
};
