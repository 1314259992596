import { Field } from 'react-final-form';
import { Error, ErrorIcon } from '@oms/ui-field';

type Validator = (value?: string) => string | undefined;

export const composeValidators = (...validators: Validator[]) => (
  value?: any,
) =>
  validators.reduce<string | undefined>(
    (error, validator) => error || validator(value),
    undefined,
  );

export const required = (value?: any) => {
  return value ? undefined : 'Påkrevet';
};

export const notEmptyArray = (value?: string) => {
  if (value?.length === 0) return 'Påkrevet';
};

export const requiredWithMessage = (message: string) => (value?: any) =>
  value ? undefined : message;

export const noDecimals = (value?: string) =>
  value?.includes('.') || value?.includes(',')
    ? 'Ugyldig. Skriv inn et heltall.'
    : undefined;

export const posNumber = (value?: string) =>
  Number(value) >= 0 ? undefined : 'Ugyldig tall';

export const lessThanMaxYear = (value?: string) =>
  Number(value) < 100 ? undefined : 'Ugyldig tall';

export const lessThanMax = (value?: string) =>
  Number(value) < 1000000
    ? undefined
    : 'Antall handler overskrider max handler sist år';

export const ValidationError = ({ name }: { name: string }) => (
  <Field
    name={name}
    subscribe={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) =>
      touched && error ? (
        <Error>
          <ErrorIcon />
          {error}
        </Error>
      ) : null
    }
  />
);
