import createDecorator from 'final-form-calculate';

const handleDecline = (termName: string) => (
  fieldValue: boolean,
  values: any,
) => {
  if (fieldValue === false)
    return values.additionalInformation?.updates[termName];
  return false;
};

// It works, but..
export const termsDecorator = createDecorator(
  {
    field: 'additionalInformation.updates.productsAndServices', // when productsAndServices changes...
    updates: {
      'additionalInformation.updates.stockExchangeListings': val => val,
      'additionalInformation.updates.marketAndStocks': val => val,
      'additionalInformation.updates.invitations': val => val,
    },
  },
  {
    field: 'additionalInformation.updates.declineUpdates', // when declineUpdates changes...
    updates: {
      'additionalInformation.updates.productsAndServices': handleDecline(
        'productsAndServices',
      ),
      'additionalInformation.updates.stockExchangeListings': handleDecline(
        'stockExchangeListings',
      ),
      'additionalInformation.updates.marketAndStocks': handleDecline(
        'marketAndStocks',
      ),
      'additionalInformation.updates.invitations': handleDecline('invitations'),
    },
  },
  {
    field: [
      'additionalInformation.updates.productsAndServices',
      'additionalInformation.updates.stockExchangeListings',
      'additionalInformation.updates.marketAndStocks',
      'additionalInformation.updates.invitations',
    ],
    updates: {
      'additionalInformation.updates.declineUpdates': (
        fieldValue,
        values: any,
      ) => {
        if (fieldValue === true) {
          return false;
        }
        const {
          invitations,
          marketAndStocks,
          stockExchangeListings,
          productsAndServices,
        } = values.additionalInformation?.updates || {};
        if (
          !invitations &&
          !marketAndStocks &&
          !stockExchangeListings &&
          !productsAndServices
        )
          return true;
        return false;
      },
    },
  },
);
