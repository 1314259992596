import { Payload } from 'components/ExpediencyTest/types';
import { EXPEDIENCY_TEST_URL } from 'constants/Jaws';
import { del, FetchError, get, post } from 'utils/fetch';

type ExpediencyTest = Payload;

// TODO: These helpers (and some others) wound up pretty duplicated, refactor?

export const getExpediencyTest = async (userId: string) => {
  if (!userId) return;

  const response = await get(`${EXPEDIENCY_TEST_URL}/${userId}`);

  if (!response.ok) {
    throw new FetchError({
      message: 'Henting av expediency test feilet',
      response,
    });
  }

  const data = await response.json();
  return data as ExpediencyTest;
};

export const delExpediencyTest = async (userId: string) => {
  if (!userId) return;

  const response = await del(`${EXPEDIENCY_TEST_URL}/${userId}`);

  if (!response.ok) {
    throw new FetchError({
      message: 'Sletting av eksisterende hensiktsmessighets-sjekk feilet',
      response,
    });
  }

  return response;
};

type MutateExpediencyTestVariables = {
  userId: string;
  payload: ExpediencyTest;
};
export const postExpediencyTest = async ({
  userId,
  payload,
}: MutateExpediencyTestVariables) => {
  if (!userId) return;

  const response = await post(
    `${EXPEDIENCY_TEST_URL}/${userId}`,
    JSON.stringify(payload),
  );

  if (!response.ok) {
    throw new FetchError({
      message: 'Lagring av hensiktsmessighets-test feilet',
      response: response.clone(),
    });
  }

  return response;
};

type PersonalInfo = {
  name: string;
  phoneNumber: string;
  email: string;
  address: string;
  postalCode: string;
  bankAccount: string;
  vpsAccount: string;
};

export const getPersonalInfo = async (userId: string) => {
  if (!userId) return;

  const response = await get(`${EXPEDIENCY_TEST_URL}/${userId}/personal-info`);

  if (!response.ok) {
    throw new FetchError({
      message: 'Henting av personlig informasjon feilet',
      response,
    });
  }

  try {
    const data = await response.json();
    return data as PersonalInfo;
  } catch (error) {
    return;
  }
};

export const getExpediencyFlag = async (userId: string) => {
  if (!userId) return;

  const response = await get(`${EXPEDIENCY_TEST_URL}/${userId}/must-take-test`);

  if (!response.ok) {
    throw new FetchError({
      message: 'Henting av expediency test feilet',
      response,
    });
  }

  try {
    const data = await response.json();
    return data as Boolean;
  } catch (error) {
    return;
  }
};

type SigningStatus = {
  status:
    | 'CREATED'
    | 'COMPLETED'
    | 'REJECTED'
    | 'EXPIRED'
    | 'DELETED'
    | 'FAILED';
};
export const getSigningStatus = async (userId: string) => {
  if (!userId) return;

  const response = await get(`${EXPEDIENCY_TEST_URL}/${userId}/signing-status`);

  if (!response.ok) {
    throw new FetchError({
      message: 'Henting av signeringsstatus feilet',
      response,
    });
  }

  try {
    const data = await response.json();
    return data as SigningStatus;
  } catch (error) {
    return;
  }
};

export const postSigning = async (userId: string) => {
  if (!userId) return;

  const response = await post(`${EXPEDIENCY_TEST_URL}/${userId}/sign`);

  if (!response.ok) {
    throw new FetchError({
      message: 'Kunne ikke sende videre til signering',
      response,
    });
  }

  try {
    const data = await response.json();
    return data as unknown;
  } catch (error) {
    return;
  }
};
