import Cookies from 'js-cookie';
import {
  FormValues,
  KnowledgeLevel,
  Payload,
} from 'components/ExpediencyTest/types';

export const calculateExpediency = (
  knowledgeLevel: KnowledgeLevel,
  years: number,
) => (knowledgeLevel === 'LOW' || years === 0 ? 'NO' : 'YES');

export const checkIsPDFRender = () => {
  const cookieData = Cookies.getJSON('generatePDF');
  const isPDFRender = typeof cookieData !== 'undefined';
  return isPDFRender;
};

/** Some of the form components can't handle boolean values */
export const parseStringBooleans = (
  stringBoolean: 'true' | 'false' | undefined,
) => {
  return stringBoolean === 'true';
};

export const booleanToString = (boolean: boolean | undefined) => {
  if (boolean === undefined) return undefined;
  return boolean.toString() as 'true' | 'false';
};

export const getPayload = (formValues: any): Payload => {
  const { sendEmail, ...values } = formValues;
  return {
    contents: {
      ...values,
      education: {
        highestCompleted: formValues.education?.highestCompleted,
        relevantEducation: parseStringBooleans(
          formValues.education?.relevantEducation,
        ),
        relevantEducationDescription:
          formValues.education.relevantEducationDescription,
      },
      profession: {
        currentProfession: formValues.profession?.currentProfession,
        relevantExperience: parseStringBooleans(
          formValues.profession?.relevantExperience,
        ),
        relevantExperienceDescription:
          formValues.profession.relevantExperienceDescription,
      },
      pepDetails: {
        ...formValues.pepDetails,
        IamPep: parseStringBooleans(formValues.pepDetails?.IamPep),
        familyIsPep: parseStringBooleans(formValues.pepDetails?.familyIsPep),
      },
    },
    isPoliticallyExposedPerson: parseStringBooleans(
      formValues.isPoliticallyExposedPerson,
    ),
    sendEmail: formValues.sendEmail,
  };
};

export const getFormValues = (
  expediencyTest: Payload | undefined,
): Partial<FormValues> => {
  if (!expediencyTest) return {};
  const { contents } = expediencyTest;
  return {
    ...contents,
    additionalInformation: contents?.additionalInformation,
    education: {
      highestCompleted: contents?.education?.highestCompleted,
      relevantEducation: booleanToString(
        contents?.education?.relevantEducation,
      ),
      relevantEducationDescription:
        contents?.education.relevantEducationDescription,
    },
    profession: {
      currentProfession: contents?.profession?.currentProfession,
      relevantExperience: booleanToString(
        contents?.profession?.relevantExperience,
      ),
      relevantExperienceDescription:
        contents?.profession.relevantExperienceDescription,
    },
    pepDetails: {
      ...contents?.pepDetails,
      IamPep: booleanToString(contents?.pepDetails?.IamPep),
      familyIsPep: booleanToString(contents?.pepDetails?.familyIsPep),
    },
    isPoliticallyExposedPerson: contents?.isPoliticallyExposedPerson,
    sendEmail: contents?.sendEmail,
  };
};
