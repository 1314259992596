import { Box } from '@oms/ui-box';
import { Button } from '@oms/ui-button';
import { DateInput } from '@oms/ui-date-input';
import { Field, RenderField } from '@oms/ui-field';
import { Field as FField } from 'react-final-form';
import { Select } from '@oms/ui-select';
import { Stack } from '@oms/ui-stack';
import { TextInput } from '@oms/ui-text-input';
import { FieldArray } from 'react-final-form-arrays';
import { checkIsPDFRender } from 'utils/expediencyTest';
import { countryOptions } from 'utils/locales';
import { required } from '../validate';
import { positionOptions } from './utils';

const initialPositions: any[] = [];

type Props = { name: string };

const PersonArray = ({ name }: Props) => {
  const isPDF = checkIsPDFRender();
  return (
    <FieldArray name={name}>
      {({ fields }) => (
        <>
          {fields.map((personFieldName: string, index: number) => (
            <Stack
              my={4}
              as="fieldset"
              key={personFieldName}
              gap={2}
              border="sm"
              p={4}
            >
              <Box>
                <label>Person nr. {index + 1}</label>
                {index !== 0 && (
                  <Button ml={4} size="xs" onClick={() => fields.remove(index)}>
                    Fjern person
                  </Button>
                )}
              </Box>

              <Field
                name={`${personFieldName}.personalia.givenName`}
                as={TextInput}
                label="Fornavn"
                validate={required}
              />

              <Field
                name={`${personFieldName}.personalia.surName`}
                as={TextInput}
                label="Etternavn"
                validate={required}
              />

              <Field
                name={`${personFieldName}.personalia.address`}
                as={TextInput}
                label="Adresse"
                autoComplete="address"
                validate={required}
              />

              <Field
                name={`${personFieldName}.personalia.email`}
                as={TextInput}
                label="E-post"
                autoComplete="email"
                validate={required}
              />

              <Field
                name={`${personFieldName}.personalia.phoneNumber`}
                as={TextInput}
                label="Mobilnummer"
                validate={required}
              />

              <FieldArray
                name={`${personFieldName}.positionList`}
                initialValue={initialPositions}
              >
                {({ fields: positions }) => (
                  <Box>
                    {positions.map((positionFieldName, index) => (
                      <Stack gap={2} mt={8}>
                        <Box>
                          <label>Stilling nr. {index + 1}</label>
                          {index !== 0 && (
                            <Button
                              ml={4}
                              size="xs"
                              onClick={() => positions.remove(index)}
                            >
                              Fjern stilling
                            </Button>
                          )}
                        </Box>
                        <Field
                          name={`${positionFieldName}.position`}
                          as={Select}
                          label="Offentlig stilling/politisk verv"
                          items={positionOptions}
                          validate={required}
                        />

                        <Field
                          name={`${positionFieldName}.title`}
                          as={TextInput}
                          label="Stillingstittel"
                          validate={required}
                        />

                        <Field
                          name={`${positionFieldName}.organization`}
                          as={TextInput}
                          label="Arbeidsgiver"
                          validate={required}
                        />

                        {isPDF ? (
                          <>
                            <FField
                              name={`${positionFieldName}.fromDate`}
                              component={props => (
                                <RenderField
                                  name={props.input.name}
                                  as={DateInput}
                                  label="Start"
                                  value={new Date(props.input.value)}
                                />
                              )}
                            />
                            <FField
                              name={`${positionFieldName}.toDate`}
                              component={props => {
                                if (!props?.input?.value) return null;
                                return (
                                  <RenderField
                                    name={props?.input?.name}
                                    as={DateInput}
                                    label="Slutt"
                                    value={new Date(props?.input?.value)}
                                  />
                                );
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <Field
                              name={`${positionFieldName}.fromDate`}
                              as={DateInput}
                              label="Start"
                              validate={required}
                            />
                            <Field
                              name={`${positionFieldName}.toDate`}
                              as={DateInput}
                              label="Slutt"
                            />
                          </>
                        )}
                        <Field
                          name={`${positionFieldName}.country`}
                          as={Select}
                          label="Land"
                          validate={required}
                          items={countryOptions}
                        />
                      </Stack>
                    ))}
                    <Button
                      type="button"
                      variant="tertiary"
                      onClick={() => positions.push({})}
                    >
                      Legg til stilling
                    </Button>
                  </Box>
                )}
              </FieldArray>
            </Stack>
          ))}
          <Button
            type="button"
            variant="tertiary"
            onClick={() => fields.push({})}
          >
            Legg til person
          </Button>
        </>
      )}
    </FieldArray>
  );
};

export default PersonArray;
